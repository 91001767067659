import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import { jsonToFormData, validationErrorFromAPI } from 'helpers/utils';
import { FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select, { ActionMeta, MultiValue } from 'react-select';
// import Select from 'react-select/dist/declarations/src/Select';
import { toast } from 'react-toastify';
import { addService, deleteService, getService } from 'services/services';

interface Common {
  id: number;
  name: string;
}

interface WorkOrder {
  id: number;
  name: string;
}
interface Contractor {
  id: number;
  first_name: string;
  last_name: string;
}

interface Field {
  wo_id: string;
  site_name: string;
  site_id: string;
  country: string;
  state: string;
  city: string;
  address: string;
  contractor_id: string;
  start_date: string | Date;
  end_date: string | Date;
  budget: string;
  description: string;
  sac_code: '';
  uom: '';
  actual_start_date?: string | Date; // Make optional with '?'
  actual_end_date?: string | Date; // Make optional with '?'
}

const EditSiteDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useState<Common[]>([]);
  const [city, setCity] = useState<Common[]>([]);
  const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>([]);
  const [contractorList, setContractorList] = useState<Contractor[]>([]);
  const [itemCode, setItemCode] = useState<any>([]);
  const [collection, setCollection] = useState<any>([]);
  const [itemIds, setItemIds] = useState<any>([]);
  // const [disableItem, setDisableItem] = useState<any>([]);

  const [fields, setFields] = useState<Field>({
    wo_id: '',
    site_name: '',
    site_id: '',
    country: '101',
    state: '',
    city: '',
    address: '',
    contractor_id: '',
    start_date: '',
    end_date: '',
    budget: '',
    description: '',
    sac_code: '',
    uom: ''
  });

  const [selectedItems, setSelectedItems] = useState<any>([]);

  useEffect(() => {
    const data = itemIds.map((item: any) => ({
      ...item,
      id: item.id,
      mainId: item.itemcode.id,
      siteMapId: item.id,
      item_code: item.itemcode.item_code,
      amount: item.itemcode.amount,
      Item_amount: item.Item_amount,
      company: item.itemcode.company.name,
      gst_no: item.itemcode.company.gst_no
    }));
    setCollection(data);
  }, [itemIds]);

  useEffect(() => {
    if (id) {
      fetchCompleteDetails(id);
      getItemCode();
    }
  }, [id]);

  const selectItemCode = itemCode.map((item: any) => ({
    value: item.id,
    label: item.item_code + '-' + item.company.name + '-' + item.amount
  }));

  const getItemCode = async () => {
    const response = await getService('itemcode');
    if (response.status === 200) {
      setItemCode(response.data);
    }
  };
  const handleDelete = async (itemId: any) => {
    const response = await deleteService(`site-item-mape/${itemId}`);
    if (response.status === 200) {
      toast.success('Successfully deleted!');
      // handleRefresh();
      fetchCompleteDetails(id);
    } else {
      toast.error('Failed');
    }
  };

  const fetchCompleteDetails = async (id: any) => {
    const response = await getService(`site/${id}`);
    if (response.status === 200) {
      setItemIds(response.data.detail.siteitem);
      setFields(prevFields => ({
        ...prevFields,
        wo_id: response.data.detail.wo_id,
        site_name: response.data.detail.site_name,
        site_id: response.data.detail.id,
        contractor_id: response.data.detail.contractor_id,
        start_date: response.data.detail.start_date,
        end_date: response.data.detail.end_date,
        budget: response.data.detail.budget,
        description: response.data.detail.description,
        country: response.data.detail.country,
        state: response.data.detail.state,
        city: response.data.detail.city,
        address: response.data.detail.address,
        sac_code: response.data.detail.sac_code,
        uom: response.data.detail.uom
      }));

      const selectedItemsNumbers = response.data.detail.siteitem.map(
        (item: any) => ({
          ...item,
          value: item.item_id,
          label:
            item.itemcode.item_code +
            '-' +
            item.itemcode.company.name +
            '-' +
            item.itemcode.amount
        })
      );
      setSelectedItems(selectedItemsNumbers);
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  useEffect(() => {
    const replaceIdAndAmount = (itemCode: any, itemIds: any) => {
      const siteMap = itemCode.map((item: any) => ({
        ...item,
        siteMapId: 0
      }));
      return siteMap.map((item: any) => {
        const replacementItem = itemIds.find(
          (ri: any) => ri.itemcode.id === item.id
        );
        if (replacementItem) {
          return {
            ...item,
            siteMapId: replacementItem.id ?? null,
            Item_amount: replacementItem.Item_amount ?? item.amount
            // siteMapId: replacementItem.id
          };
        } else {
          return {
            ...item,
            id: item?.id || null,
            Item_amount: item.Item_amount ?? item.amount // Keep original amount if no replacement found
          };
        }
      });
    };
    const updatedItems = replaceIdAndAmount(itemCode, itemIds);
    setItemCode(updatedItems);
  }, [selectedItems, itemIds]);

  const handleQuantityChange = async (id: any, value: any) => {
    setCollection((prevQuantities: any[]) =>
      prevQuantities.map(item =>
        item.id === id ? { ...item, amt: value } : item
      )
    );
  };

  // useEffect(() => {
  //   const defaultSelectedItems = itemIds.map((item: any) => item.itemcode.id);
  //   const defaultSelectedItemsNumbers = defaultSelectedItems;
  //   setSelectedItems(defaultSelectedItemsNumbers);
  //   setDisableItem(defaultSelectedItems);

  //   const filteredData = itemCode.filter((item: any) =>
  //     defaultSelectedItemsNumbers.includes(item.id)
  //   );
  //   setCollection(filteredData);
  // }, []);

  // const isOptionSelectable = (id: any) => {
  //   return !disableItem.includes(id);
  // };

  const handleSelectItemCode = (
    newValue: MultiValue<any>,
    actionMeta: ActionMeta<any>
  ) => {
    setSelectedItems(newValue);

    const selectedItemsNumbers = newValue.map(row => parseInt(row.value, 10));

    const filteredData = itemCode.filter((item: any) =>
      selectedItemsNumbers.includes(item.id)
    );
    setCollection(filteredData);
  };

  const handleItemCode = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      option => option.value
    );
    setSelectedItems(selectedOptions);
    const selectedItemsNumbers = selectedOptions.map(id => parseInt(id, 10));
    const filteredData = itemCode.filter((item: any) =>
      selectedItemsNumbers.includes(item.id)
    );
    setCollection(filteredData);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));

    if (name === 'state') {
      getCity(value);
    }
  };

  useEffect(() => {
    getState();
    getWorkOrder();
    getContractor();
  }, []);

  useEffect(() => {
    if (fields.state) {
      getCity(fields.state);
    }
  }, [fields.state]);

  const getWorkOrder = async () => {
    const response = await getService('workorder');
    if (response.status === 200) {
      setWorkOrderList(response.data);
    }
  };
  const getContractor = async () => {
    const response = await getService('contractor');
    if (response.status === 200) {
      setContractorList(response.data);
    }
  };
  const getState = async () => {
    const response = await getService(`state-by-country/${101}`);
    if (response.status === 200) {
      setState(response.data);
    }
  };
  const getCity = async (state_id: string) => {
    const response = await getService(`city-by-state/${state_id}`);
    if (response.status === 200) {
      setCity(response.data);
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    // fields.start_date = formatDateForAPI(fields.start_date);
    // fields.end_date = formatDateForAPI(fields.end_date);
    const jsonData = {
      ...fields, // Spread operator to include all properties from fields
      collection: collection // Add collection key with quantities array
    };
    const data = jsonToFormData(jsonData);
    let response: any = {}; // Declare response variable with let instead of const
    if (id) {
      response = await addService(`site/${id}`, jsonData); // Remove const keyword
    } else {
      response = await addService('site', data); // Remove const keyword
    }

    if (response?.status === 200) {
      toast.success('SuccessFully Updated', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      // navigate('/siteList');
      // navigate(`/SiteDetails/${id}`);
    } else {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };

  return (
    <div>
      <h4 className="mb-4">Edit site</h4>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6">
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTask" label="WO Name">
                <Form.Select
                  name="wo_id"
                  value={fields['wo_id']}
                  onChange={handleSelect}
                >
                  <option>Select WO Name</option>
                  {workOrderList.length > 0 &&
                    workOrderList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={8}>
              <FloatingLabel controlId="floatingInputGrid" label="Site Name">
                <Form.Control
                  type="text"
                  placeholder="Site Name"
                  name="site_name"
                  value={fields['site_name']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Site ID">
                <Form.Control
                  type="text"
                  placeholder="Site ID"
                  name="site_id"
                  value={fields['site_id']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Site Budget">
                <Form.Control
                  type="number"
                  placeholder="Site Budget"
                  name="budget"
                  value={fields['budget']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Country">
                <Form.Control
                  type="text"
                  placeholder="India"
                  name="India"
                  value={'India'}
                  // onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectPrivacy" label="State">
                <Form.Select
                  name="state"
                  value={fields['state']}
                  onChange={handleSelect}
                >
                  <option>Select State</option>
                  {state.length > 0 &&
                    state.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel
                controlId="floatingSelectPrivacy"
                label="City/Village"
              >
                <Form.Select
                  name="city"
                  value={fields['city']}
                  onChange={handleSelect}
                >
                  <option>Select City</option>
                  {city.length > 0 &&
                    city.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="Address">
                <Form.Control
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={fields['address']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingSelectTeam" label="Contractor">
                <Form.Select
                  name="contractor_id"
                  value={fields['contractor_id']}
                  onChange={handleSelect}
                >
                  <option>Select Contractor</option>
                  {contractorList.length > 0 &&
                    contractorList.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.first_name} {item.last_name}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                value={fields['start_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  setFields({
                    ...fields,
                    start_date: date
                  });
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="Project title"
                        ref={ref}
                        id="startDate"
                      />
                      <label htmlFor="startDate" className="ps-6">
                        Start date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                value={fields['end_date']}
                options={{ enableTime: false, dateFormat: 'Y-m-d' }}
                onChange={([date]) => {
                  setFields({
                    ...fields,
                    end_date: date
                  });
                }}
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="date"
                        placeholder="Deadline"
                        ref={ref}
                        id="deadline"
                      />
                      <label htmlFor="deadline" className="ps-6">
                        Estimated End Date
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="SAC Code">
                <Form.Control
                  type="text"
                  placeholder="SAC Code"
                  name="sac_code"
                  value={fields['sac_code']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="floatingInputGrid" label="UOM">
                <Form.Control
                  type="text"
                  placeholder="UOM"
                  name="uom"
                  value={fields['uom']}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <Select
                isMulti // Use isMulti instead of multiple
                value={selectedItems}
                onChange={handleSelectItemCode}
                options={selectItemCode}
                placeholder="Select item code..."
              />
            </Col>
            <Col xs={12} className="gy-6">
              <FloatingLabel
                controlId="floatingProjectOverview"
                label="Services description"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  name="description"
                  value={fields['description']}
                  onChange={handleChange}
                  style={{ height: '100px' }}
                />
              </FloatingLabel>
            </Col>
            {collection.length > 0 && (
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className="text-center">Sr. No</th>
                    <th>Item Code</th>
                    <th>Amount</th>
                    <th>Company Name</th>
                    <th>GST No</th>
                    <th>Final Amount</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {collection.map((item: any, index: any) => (
                    <tr key={item.id}>
                      <td className="text-center">{index + 1}</td>
                      <td>{item.item_code}</td>
                      <td>{item.amount}</td>
                      <td>
                        {item.company.name ??
                          // item.itemcode.company.name ||
                          item?.company}
                      </td>
                      <td>{item.company.gst_no || item.gst_no}</td>
                      <td>
                        <Form.Control
                          type="number"
                          value={item?.amt || item.Item_amount || item.amount}
                          onChange={e =>
                            handleQuantityChange(item.id, e.target.value)
                          }
                        />
                      </td>
                      <td className="text-center">
                        {item?.siteMapId ? (
                          <p
                            onClick={() => handleDelete(item?.siteMapId)}
                            style={{ cursor: 'pointer', color: 'red' }}
                          >
                            Delete
                          </p>
                        ) : (
                          'Edit'
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Link to={`/SiteDetails/${id}`}>
                  <Button variant="phoenix-primary" className="px-5">
                    Back
                  </Button>
                </Link>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSave}
                >
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default EditSiteDetails;
