import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Modal, Row, Table } from 'react-bootstrap';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { addService, deleteService, getService } from 'services/services';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  calculateGST,
  dateFormat,
  generateRandom8DigitNumber,
  jsonToFormData
} from 'helpers/utils';
import Avatar from 'components/base/Avatar';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { baseLocalURL } from 'config';

interface Industry {
  id: number;
  siteinventory: {
    material: {
      name: string;
    };
  };
  material: {
    name: string;
    image: any;
  };
  tpgst: number;
  gst: number;
  site_rate: number;
  rate_per_site: number;
  quantity: number;
  status: string;
  is_dispatch: string;
  challan_m: {
    challan: {
      challan_no: string;
    };
  };
}

interface Field {
  site_id: string;
  material_id: string;
  unit_id: string;
  quantity?: string;
  rate_per_site?: any;
  site_rate?: any;
  gst?: any;
  tpgst?: any;
  status: string;
}
interface Material {
  id: any;
  name: string;
  unitdata?: any;
  site_name: string;
}
interface Unit {
  id: any;
  unit_title: string;
}

interface InventoryItem {
  id: number;
  name: string;
  material_id: number;
  challan_no: string;
  material: {
    id: number;
    name: string;
  };
}

interface Challan {
  site_id: any;
  challan_no: any;
  vehicle_no: any;
  driver_name: any;
  driver_mobile: any;
  site_inventory_id: any;
}

interface CellProps {
  row: {
    original: Industry;
  };
}

type EditAction = () => void;

interface Props {
  siteId: any; // Define the prop type
}
const InventoryViewTable: React.FC<Props> = props => {
  // eslint-disable-next-line react/prop-types
  const { siteId } = props;

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [challanshow, setChallanShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [inventryList, setInventryList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [siteChallan, setSiteChallan] = useState([]);
  const [inventoryId, setInventoryId] = useState('');
  const [value, setValue] = useState('');
  const [unitName, setUnitName] = useState('');
  const [materialName, setMaterialName] = useState('');
  const [approveData, setApproveData] = useState([]);
  const [materialID, setMaterialID] = useState('');
  const [statusID, setStatusID] = useState('');
  const [challanID, setChallanID] = useState('');
  const [totalItem, setTotalItem] = useState(0);
  const [fields, setFields] = useState<Field>({
    site_id: '',
    material_id: '',
    unit_id: '',
    quantity: '',
    rate_per_site: '',
    site_rate: '',
    gst: '',
    tpgst: '',
    status: ''
  });

  const [role, setRole] = useState(0);

  const [quantities, setQuantities] = useState(() =>
    approveData.map((item: any) => ({ id: item.id, qty: '' }))
  );

  useEffect(() => {
    const totalQty = quantities.reduce(
      (acc, item) => acc + Number(item.qty),
      0
    );
    setTotalItem(totalQty);
  }, [quantities]);

  const handleQuantityChange = (id: any, value: any) => {
    setQuantities(prevQuantities =>
      prevQuantities.map(item =>
        item.id === id ? { ...item, qty: value } : item
      )
    );
  };

  useEffect(() => {
    setQuantities(approveData.map((item: any) => ({ id: item.id, qty: '' })));
  }, [approveData]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const role = Number(localStorage.getItem('role'));
      setRole(role);
    }
  }, []);

  const [state, setState] = useState<Challan>({
    site_id: '',
    challan_no: '',
    vehicle_no: '',
    driver_name: '',
    driver_mobile: '',
    site_inventory_id: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    console.log('namedd', name, value);
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));

    if (name === 'quantity') {
      const quantity = parseFloat(value); // Convert value to a float
      const ratePerSite = fields['rate_per_site']
        ? parseFloat(fields['rate_per_site'])
        : 1; // Convert rate_per_site to a float
      const site_rate = quantity * ratePerSite; // Perform the calculation

      const gst = fields['gst'] ? parseFloat(fields['gst']) : 0; // Convert rate_per_site to a float
      const totalAmount = calculateGST(site_rate, gst); // Perform the calculation
      setFields(prevFields => ({
        ...prevFields, // Spread the previous fields
        site_rate: site_rate, // Update the specific field with the new value,
        tpgst: totalAmount // Update the specific field with the new value,
      }));
    }
    if (name === 'rate_per_site') {
      const quantity = parseFloat(value); // Convert value to a float
      const ratePerSite = fields['quantity']
        ? parseFloat(fields['quantity'])
        : 1; // Convert rate_per_site to a float
      const site_rate = quantity * ratePerSite;
      // fields['site_rate'] = site_rate; // Perform the calculation

      const gst = fields['gst'] ? parseFloat(fields['gst']) : 0; // Convert rate_per_site to a float
      const totalAmount = calculateGST(site_rate, gst);
      // fields['tpgst'] = totalAmount; // Perform the calculation
      setFields(prevFields => ({
        ...prevFields, // Spread the previous fields
        site_rate: site_rate, // Update the specific field with the new value,
        tpgst: totalAmount // Update the specific field with the new value,
      }));
    }
    if (name === 'gst') {
      const totalAmount = calculateGST(fields['site_rate'], parseFloat(value));

      setFields(prevFields => ({
        ...prevFields, // Spread the previous fields
        tpgst: totalAmount // Update the specific field with the new value,
      }));
    }
  };

  const handleChangeStatus = (val: string) => {
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      status: val // Update the specific field with the new value
    }));
  };

  const handleShow = async (data: any) => {
    try {
      setShow(true);
      setFullscreen(true);
      setInventoryId(data.id);
      setMaterialName(data.material.name);
      setUnitName(data.material.unitdata.unit_title);
      setFields({
        site_id: data.site_id,
        material_id: data.material_id,
        unit_id: data.unit,
        quantity: data.quantity,
        rate_per_site: data.rate_per_site,
        site_rate: data.site_rate,
        gst: data.gst,
        tpgst: data.tpgst,
        status: value
      });

      // Then, call fetchItems and wait for it to complete
      // await fetchItems();
      const response = await getService(
        `master_inventory/${data.material_id}/${data.site.state}`
      );

      if (response.status === 200) {
        setApproveData(response.data);
      } else if (response.status === 500) {
        setApproveData(response.data);
      }

      // If a second call to fetchItems is needed, handle it here
      // await fetchItems(); // Uncomment if necessary
    } catch (error) {
      console.error('Error handling show:', error);
      // Handle the error appropriately, e.g., notify the user
    }
  };

  const handleChallanShow = () => {
    if (table.getGroupedSelectedRowModel().rows.length > 0) {
      setChallanShow(true);
      const ids: any[] = [];
      table.getGroupedSelectedRowModel().rows.map((row: any) => {
        ids.push(row.original.id);
      });
      setState({
        site_id: siteId,
        challan_no: generateRandom8DigitNumber(),
        vehicle_no: '',
        driver_name: '',
        driver_mobile: '',
        site_inventory_id: ids.join(',')
      });

      // const original: Industry[] = table.getGroupedSelectedRowModel().rows;
    } else {
      toast.error('PLEASE SELECT APPROVED MATERIAL FIRST');
    }
  };

  const handleChangeChallan = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target

    setState(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSaveChallan = async () => {
    const data = jsonToFormData(state);
    let response: any = {};
    response = await addService(`challan`, data);
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      handleClose();
      navigate(`/challan/${response?.data?.id}`);
    }
  };

  const confirmAlert = (id: any) => {
    setShowAlert(true);
    setInventoryId(id);
  };
  const removeApi = async () => {
    const response = await deleteService(`site_inventory/${inventoryId}`);
    if (response?.status === 200) {
      toast.success('Remove SuccessFully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      fetch();
      setShowAlert(false);
      setInventoryId('');
    }
  };

  const handleClose = () => {
    setShow(false);
    setInventoryId('');
  };

  const challanClose = () => {
    setChallanShow(false);
    setInventoryId('');
  };

  const handleSave = async () => {
    if (Number(fields.quantity) == totalItem) {
      const jsonData = {
        ...fields, // Spread operator to include all properties from fields
        collection: quantities // Add collection key with quantities array
      };
      let response: any = {};
      response = await addService(`site_inventory/${inventoryId}`, jsonData);
      if (response?.status === 200) {
        toast.success('SuccessFully Added', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        fetch();
        handleClose();
      }
    } else if (Number(fields.quantity) != totalItem) {
      toast.error('Please Enter correct Quantity, Quantity Mismatch');
    } else {
      toast.error('Faild');
    }
  };

  const handleChallanSelect = async (selectedChallanId: string) => {
    if (selectedChallanId) {
      setChallanID(selectedChallanId);
      const response = await getService(
        `site_inventory_by_filter_id/${siteId}/${statusID ? statusID : 0}/${
          materialID ? materialID : 0
        }/${selectedChallanId}`
      );
      if (response.status === 200) {
        setInventryList(response.data);
      }
    } else {
      `site_inventory_by_filter_id/${siteId}/${statusID ? statusID : 0}/${
        materialID ? materialID : 0
      }/${challanID ? challanID : ''}`;
    }
  };

  const handleApprovalStatusChange = async (selectedStatus: string) => {
    if (selectedStatus) {
      setStatusID(selectedStatus);
      const response = await getService(
        `site_inventory_by_filter_id/${siteId}/${selectedStatus}/${
          materialID ? materialID : 0
        }/${challanID ? challanID : ''}`
      );
      if (response.status === 200) {
        setInventryList(response.data);
      }
    } else {
      `site_inventory_by_filter_id/${siteId}/${statusID ? statusID : 0}/${
        materialID ? materialID : 0
      }/${challanID ? challanID : ''}`;
    }
  };

  const handleMaterialChange = async (selectedMaterial: string) => {
    if (selectedMaterial) {
      setMaterialID(selectedMaterial);
      const response = await getService(
        `site_inventory_by_filter_id/${siteId}/${
          statusID ? statusID : 0
        }/${selectedMaterial}/${challanID ? challanID : ''}`
      );
      if (response.status === 200) {
        setInventryList(response.data);
      }
    } else {
      `site_inventory_by_filter_id/${siteId}/${statusID ? statusID : 0}/${
        materialID ? materialID : 0
      }/${challanID ? challanID : ''}`;
    }
  };

  const fetchSiteChallan = async () => {
    const response = await getService(`challan_no_list_by_site_id/${siteId}`);
    if (response.status === 200) {
      setSiteChallan(response.data);
    }
  };

  const fetchMaterial = async () => {
    const response = await getService('material');
    if (response.status === 200) {
      setMaterialList(response.data);
    }
  };

  const fetch = async () => {
    const response = await getService(
      `site_inventory_by_filter_id/${siteId}/${0}/${0}/`
    );
    if (response.status === 200) {
      setInventryList(response.data);
    }
  };

  useEffect(() => {
    fetch();
    fetchMaterial();
    fetchSiteChallan();
  }, []);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const membersTablecolumns: ColumnDef<Industry>[] = [
    {
      accessorKey: 'material.name',
      header: 'Material Name',
      cell: ({ row: { original } }) => {
        const { material } = original;
        if (!material) {
          return null; // Handle case where material is null or undefined
        }

        const { name: materialName, image: materialImage } = material;

        return (
          <Link to="/" className="d-flex align-items-center text-body">
            <Avatar
              src={
                materialImage ? `${baseLocalURL}/public/${materialImage}` : ''
              }
              size="m"
            />
            <h5 className="mb-0 ms-3 text-body-emphasis fw-semibold">
              {materialName}
            </h5>
          </Link>
        );
      },
      meta: {
        headerProps: {
          className: 'px-1'
        },
        cellProps: {
          className: 'align-middle white-space-nowrap py-2'
        }
      }
    },
    {
      accessorKey: 'material.unitdata.unit_title',
      header: 'Unit',
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-body fw-semibold' }
      }
    },
    // {
    //   accessorKey: 'material.current_stock',
    //   header: 'Current Stock',
    //   meta: {
    //     headerProps: { style: { width: '10%' } },
    //     cellProps: { className: 'text-body' }
    //   }
    // },
    // ...(role === 1 || role === 2 || role === 3
    //   ? [
    //       {
    //         accessorKey: 'material.current_stock',
    //         header: 'Current Stock',
    //         meta: {
    //           headerProps: { style: { width: '10%' } },
    //           cellProps: { className: 'text-center' }
    //         }
    //       }
    //     ]
    //   : []),
    {
      accessorKey: 'quantity',
      header: 'Quantity',
      meta: {
        headerProps: {
          style: { width: '10%' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'rate_per_site',
      header: 'Rate',
      meta: {
        headerProps: {
          style: { width: '10%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'gst',
      header: 'GST',
      cell: ({ row: { original } }) => {
        const { gst } = original;
        return (
          <p className="mb-0 ms-3 text-body-emphasis fw-semibold">{gst}%</p>
        );
      },
      meta: {
        headerProps: {
          style: { width: '10%' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'tpgst',
      header: 'Amount',
      meta: {
        headerProps: {
          style: { width: '10%' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'Approval Status',
      header: 'Approval Status',
      cell: ({ row: { original } }) => {
        const { id, status } = original;
        return (
          <div key={id + status}>
            {status == '1' ? (
              <p className="mb-0 ms-3 fw-semibold text-info">
                Approval Pending
              </p>
            ) : status == '2' ? (
              <p className="mb-0 ms-3 fw-semibold text-success">
                Approve to Main
              </p>
            ) : status == '3' ? (
              <p className="mb-0 ms-3 fw-semibold text-warning">
                Approve to Local
              </p>
            ) : (
              <p className="mb-0 ms-3 fw-semibold text-danger">Reject</p>
            )}
          </div>
        );
      },
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'challan_m.challan.challan_no',
      header: 'Challan No.',
      meta: {
        headerProps: {
          style: { width: '10%' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      accessorKey: 'status',
      header: 'Dispatch Status',
      cell: ({ row: { original } }: CellProps) => {
        // Safely extract the challan_no from the original data
        const challan_no = original.challan_m?.challan?.challan_no ?? '';

        // Render the status based on the presence of challan_no
        return <>{challan_no ? 'Dispatched' : ''}</>;
      },
      meta: {
        headerProps: {
          style: { width: '21%', minWidth: '200px' },
          className: 'text-center'
        },
        cellProps: {
          className: 'text-center text-body-tertiary'
        }
      }
    },
    {
      id: 'action',
      cell: ({ row: { original } }) => {
        const { id } = original;
        if (role === 1 || role === 2) {
          return (
            <>
              {original?.challan_m === null ? (
                <RevealDropdownTrigger>
                  <RevealDropdown>
                    <ActionDropdownItems
                      editAction={() => handleShow(original)}
                      removeAction={() => confirmAlert(id)}
                    />
                  </RevealDropdown>
                </RevealDropdownTrigger>
              ) : (
                ''
              )}
            </>
          );
        } else {
          return null;
        }
      },
      meta: {
        headerProps: { style: { width: '10%' }, className: 'text-end' },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: inventryList,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    enableRowSelection: (row: {
      original: {
        status: number;
        challan_m: null;
      };
    }) =>
      row.original?.challan_m === null && row.original?.status == 2
        ? true
        : false
  });

  // console.log(table.getSelectedRowModel().rows) //get full client-side selected rows
  // console.log(table.getFilteredSelectedRowModel().rows) //get filtered client-side selected rows
  // console.log('======', table.getGroupedSelectedRowModel().rows); //get grouped client-side selected rows

  return (
    <div>
      {showAlert && (
        <ConfirmationModal
          name={'Inventory'}
          show={showAlert}
          handleClose={() => setShowAlert(false)}
          handleAction={() => removeApi()}
        />
      )}
      <div className="mb-9">
        <h3 className="mb-5">Inventory</h3>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Search members"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingSelectTask" label="Material">
                  <Form.Select
                    name="Material Filter"
                    onChange={e => handleMaterialChange(e.target.value)}
                  >
                    <option value="0">Select Material</option>
                    {materialList.length > 0 &&
                      materialList.map((item: InventoryItem) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingSelectTask"
                  label="Approval Status"
                >
                  <Form.Select
                    name="Approval Status"
                    onChange={e => handleApprovalStatusChange(e.target.value)}
                  >
                    <option value="0">Select Status</option>
                    <option value="1">Approval Pending</option>
                    <option value="2">Approve to Main</option>
                    <option value="3">Approve to Local</option>
                    <option value="4">Reject</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel
                  controlId="floatingSelectTask"
                  label="Challan No."
                >
                  <Form.Select
                    name="Challan Filter"
                    onChange={e => handleChallanSelect(e.target.value)}
                  >
                    <option value="0">Select Challan</option>
                    {siteChallan.length > 0 &&
                      siteChallan.map((item: InventoryItem) => (
                        <option key={item.id} value={item.id}>
                          {item.challan_no}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button>
                {role === 1 && (
                  <Button
                    onClick={handleChallanShow}
                    variant="primary"
                    className="px-5"
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Create Challan
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
          <Modal show={show} fullscreen={true} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Inventory Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6">
                    <Col sm={4} md={4}>
                      <FloatingLabel
                        controlId="floatingSelectPrivacy"
                        label="Material Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Unit Name"
                          name="unit"
                          value={materialName} // Use the state variable
                          readOnly // Disable user input
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={3}>
                      <FloatingLabel controlId="floatingInputGrid" label="Unit">
                        <Form.Control
                          type="text"
                          placeholder="Unit Name"
                          name="unit"
                          value={unitName} // Use the state variable
                          readOnly // Disable user input
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={3}>
                      <FloatingLabel controlId="floatingInputGrid" label="QTY">
                        <Form.Control
                          type="number"
                          placeholder="Quantity"
                          name="quantity"
                          value={fields['quantity']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={3}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Per site rate"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Per site rate"
                          name="rate_per_site"
                          value={fields['rate_per_site']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={3}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Site rate"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Site rate"
                          name="site_rate"
                          value={fields['site_rate']}
                          onChange={handleChange}
                          disabled
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={3}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="GST %"
                      >
                        <Form.Control
                          type="text"
                          placeholder="GST"
                          name="gst"
                          value={fields['gst']}
                          onChange={handleChange}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={6} md={3}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="T.P+GST"
                      >
                        <Form.Control
                          type="number"
                          placeholder="T.P+GST"
                          name="tpgst"
                          value={fields['tpgst']}
                          onChange={handleChange}
                          disabled
                        />
                      </FloatingLabel>
                    </Col>
                    <ToggleButtonGroup
                      type="radio"
                      name="options"
                      value={fields['status']}
                      onChange={handleChangeStatus}
                    >
                      <ToggleButton id="4" variant="outline-primary" value="4">
                        Reject
                      </ToggleButton>
                      <ToggleButton id="2" variant="outline-primary" value="2">
                        Approve for main
                      </ToggleButton>
                      <ToggleButton id="3" variant="outline-primary" value="3">
                        Approve for local
                      </ToggleButton>
                      <ToggleButton id="1" variant="outline-primary" value="1">
                        Pending
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Row>
                </Col>
                {approveData.length > 0 && fields.status == '2' ? (
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th className="text-center">Sr. No</th>
                        <th>Item</th>
                        <th>Bar Code</th>
                        <th>Batch no</th>
                        <th>Expire Date</th>
                        <th>Stock</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {approveData.map((item: any, index: any) => (
                        <tr key={item.id}>
                          <td className="text-center">{index + 1}</td>
                          <td>{item.material.name}</td>
                          <td>{item.barcode}</td>
                          <td>{item.batch_no}</td>
                          <td>{dateFormat(item.maintan_date)}</td>
                          <td>{item.stock}</td>
                          <td>
                            <Form.Control
                              type="number"
                              value={
                                quantities.find(q => q.id === item.id)?.qty ||
                                ''
                              }
                              onChange={e =>
                                handleQuantityChange(item.id, e.target.value)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : approveData.length < 1 ? (
                  <h3>No data found</h3>
                ) : (
                  ''
                )}
              </Row>
              <h6>Total Quantity: {totalItem}</h6>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={challanshow} onHide={challanClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Challan detail: {state['challan_no']}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row as="form" className="g-3 mb-6">
                <Col xs={12} xl={12}>
                  <Row as="form" className="g-3 mb-6 mt-1">
                    {/* <Col sm={3} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Challan No."
                      >
                        <Form.Control
                          type="text"
                          placeholder="Challan No"
                          name="challan_no"
                          value={state['challan_no']} // Use the state variable
                          readOnly // Disable user input
                        />
                      </FloatingLabel>
                    </Col> */}
                    <Col sm={3} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Vehicle No."
                      >
                        <Form.Control
                          type="text"
                          placeholder="Vehicle No"
                          name="vehicle_no"
                          value={state['vehicle_no']}
                          onChange={handleChangeChallan}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={3} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Driver Name"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Driver Name"
                          name="driver_name"
                          value={state['driver_name']}
                          onChange={handleChangeChallan}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col sm={3} md={4}>
                      <FloatingLabel
                        controlId="floatingInputGrid"
                        label="Driver Mobile No."
                      >
                        <Form.Control
                          type="text"
                          placeholder="Driver Mobile"
                          name="driver_mobile"
                          value={state['driver_mobile']}
                          onChange={handleChangeChallan}
                          maxLength={10}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={challanClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSaveChallan}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default InventoryViewTable;
